import React from "react";

function Projects() {
  return (
    <div className="projects--wrapper">
      <h3 className="projects--title">Projects</h3>
      <h4>
        <a
          className="a--yelp"
          href="https://github.com/LyAndy09/Yelp-Comment-Search"
          target="_blank"
        >
          Yelp Comment Search
        </a>
      </h4>
      <p className="projects--yelp">
        My program streamlines the process of finding specific food and beverage items and hopefully satisfy cravings.
        When I craved something like a rose latte, my first impulse was to search for it on Yelp. 
        However, the platform's search results only provided a broad category, such as nearby coffee shops. 
        To determine if a rose latte is available, the user must either view photos of the menu or find reviews mentioning the item. 
        My program eliminates these steps and provides direct links to the businesses.
      </p>
      <h5>
        <a
          className="a--todo"
          href="https://shiny-buttercream-6863af.netlify.app/"
          target="_blank"
        >
          To-Do List
        </a>
      </h5>
      <p className="projects--todo">
      My To-Do application offers a user-friendly and efficient way to manage daily tasks. 
      Utilizing the CRUD (Create, Read, Update, Delete) method, users can easily add, view, edit, and remove their to-do items with ease. 
      The interface is built using React, providing a simple sleek and responsive design. 
      Whether you're a busy professional or a student with a full schedule, this To-Do app will help you stay organized and on track to succeed
      </p>
    </div>
  );
}

export default Projects;
