import React from "react";

function Contact() {
  return (
    <div className="contact--wrapper">
      <h4 className="contact--title">Contact</h4>
      <div>
        <a
          className="contact--GitHub"
          href="https://github.com/LyAndy09/"
          target="_blank"
        >
          GitHub
        </a>
        <a
          className="contact--LinkedIn"
          href="https://www.linkedin.com/in/andy-ly-9b0109176/"
          target="_blank"
        >
          LinkedIn
        </a>
        <a
          className="contact--Email"
          href="mailto:lyand@oregonstate.edu"
          target="_blank"
        >
          Email
        </a>
      </div>
    </div>
  );
}

export default Contact;
