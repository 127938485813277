import React from "react";

function AboutMe() {
  return (
    <div className="about--wrapper">
      <h2 className="about--title">About Me</h2>
      <p className="about--text">
      As a dedicated and driven computer science student at Oregon State University, I am working towards my goal of becoming a full-stack developer. 
      In my free time, I enjoy coding while listening to R&B music and browsing StackOverflow for any necessary technical assistance. 
      Outside of coding, I can be found working on my car, practicing olympic lifting, or indulging in my love for funny corgi videos.
      </p>
    </div>
  );
}

export default AboutMe;
